import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['mobileMenu', 'header', 'logoUnderMobileMenu', 'registerButtonUnderMobileMenu']

  connect(){
    this.toggleMenu();
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    const threshold = 100; // Cambia este valor según tus necesidades

    if (scrollPosition > threshold) {
      this.headerTarget.classList.add("bg-white", "shadow-md", "transition-all", "duration-300");
    } else {
      this.headerTarget.classList.remove("bg-white", "shadow-md", "transition-all", "duration-300");
    }
  }

  toggleMenu() {
    this.mobileMenuTarget.classList.toggle('hidden');
    this.logoUnderMobileMenuTarget.classList.toggle('hidden');

    if (this.hasRegisterButtonUnderMobileMenuTarget) {
      this.registerButtonUnderMobileMenuTarget.classList.toggle('hidden');
    }
  }

}
