import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = { tabsId: String, selectorId: String };

  select_option(event) {
    const url = event.target.value;
    this.updateTabs(url);
    Turbo.visit(url);
  }

  select_tab(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    this.updateTabs(url);
    this.updateSelector(url);
    Turbo.visit(url);
  }

  updateTabs(url) {
    const tabs = document.querySelectorAll(`#${this.tabsIdValue} a`);
    tabs.forEach((tab) => {
      const icon = tab.querySelector('.bi');
      if (tab.getAttribute('href') === url) {
        tab.classList.remove("border-transparent", "text-shadowy-300");
        tab.classList.add("border-primary", "border-b-2", "text-primary");
        icon.classList.remove("text-shadowy-300");
        icon.classList.add("text-dark");
      } else {
        tab.classList.remove("border-primary", "border-b-2", "text-primary");
        tab.classList.add("border-transparent", "text-shadowy-300");
        icon.classList.remove("text-dark");
        icon.classList.add("text-shadowy-300");
      }
    });
  }

  updateSelector(url) {
    const selector = document.getElementById(this.selectorIdValue);
    if (selector) {
      selector.value = url;
    }
  }

  updateActiveTab() {
    const activeUrl = window.location.href;
    this.updateTabs(activeUrl);
    if (this.hasSelectorTarget) {
      this.updateSelector(activeUrl);
    }
  }
}
