import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carrousel-on-truncate"
export default class extends Controller {
  static targets = ["text"];

  connect() {
    this.textTargets.forEach((el) => {
      if (this.isTruncated(el)) {
        el.classList.add("sliding-text");
      }
    });
  }

  isTruncated(element) {
    return element.scrollWidth > element.offsetWidth;
  }
}
