import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "slider", "icon", "title"]

  slide(event) {
    const buttonIndex = parseInt(event.currentTarget.dataset.index);
    this.moveSliderTo(buttonIndex);

    this.buttonTargets.forEach((button, index) => {
      const isSelected = index === buttonIndex;

      // Cambiar estilos del botón
      button.classList.toggle('opacity-75', !isSelected);
      button.classList.toggle('text-blue-600', isSelected);

      // Cambiar estilos del ícono y título asociados
      if (this.iconTargets[index]) {
        this.iconTargets[index].classList.toggle('bg-blue-600', isSelected);
        this.iconTargets[index].classList.toggle('bg-slate-500', !isSelected);
      }

      if (this.titleTargets[index]) {
        this.titleTargets[index].classList.toggle('text-blue-600', isSelected);
        this.titleTargets[index].classList.toggle('text-slate-600', !isSelected);
      }

      if (this.sliderTargets[index]) {
        if(isSelected){
          this.sliderTargets[index].classList.remove('opacity-60');
        }else{
          this.sliderTargets[index].classList.add('opacity-60');
        }
      }
    });
  }

  moveSliderTo(index) {
    const newTransform = `translateX(-${index * 100}%)`;
    this.sliderTargets.forEach(slider => {
      slider.style.transform = newTransform;
    });
  }
}
