import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'backdrop', 'profileDropdown', 'menu', 'mobileMenu'];


  switchGroup(event) {
    event.preventDefault();
    const selectedGroupId = event.target.value;

    fetch('/manager/dashboards/switch_group', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selected_group_id: selectedGroupId })
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.error) {
            Swal.fire({
              title: 'Error',
              text: data.error,
              icon: 'error',
            });
          } else {
            window.location.reload();
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  toggleMenu() {
    if (this.menuTarget.classList.contains('translate-x-[-100%]')) {
      this.menuTarget.classList.remove('translate-x-[-100%]');
      this.menuTarget.classList.add('translate-x-0');
    } else {
      this.menuTarget.classList.remove('translate-x-0');
      this.menuTarget.classList.add('translate-x-[-100%]');
    }
  }



}
