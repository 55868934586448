import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message", "modal"];

  toggleMessage() {
    const message = this.messageTarget;
    if (message.classList.contains("opacity-0")) {
      message.classList.remove("hidden", "max-h-0");
      message.classList.add("max-h-screen"); // Establece un max-height para la transición
      setTimeout(() => message.classList.remove("opacity-0"), 10);
    } else {
      message.classList.add("opacity-0");
      setTimeout(() => {
        message.classList.add("hidden", "max-h-0");
        message.classList.remove("max-h-screen");
      }, 500);
    }
  }

  toggleModal() {
    const modal = this.modalTarget;
    if (modal.classList.contains("hidden")) {
      modal.classList.remove("hidden");
      setTimeout(() => modal.classList.remove("opacity-0"), 10);
    } else {
      modal.classList.add("opacity-0");
      setTimeout(() => modal.classList.add("hidden"), 500);
    }
  }
}
