import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat-permissions"
export default class extends Controller {
  static targets = [
    "canSendOtherMessages",
    "canAddWebPagePreviews",
    "canSendPolls",
    "canSendMessages",
    "canSendAudios",
    "canSendDocuments",
    "canSendPhotos",
    "canSendVideos",
    "canSendVideoNotes",
    "canSendVoiceNotes"
  ];

  toggleDependentPermissions(event) {
    // Activa los permisos relacionados solo si se activa 'canSendOtherMessages' o 'canAddWebPagePreviews'
    if ((event.target.dataset.chatPermissionsTarget === 'canSendOtherMessages' ||
        event.target.dataset.chatPermissionsTarget === 'canAddWebPagePreviews') && event.target.checked) {
      this.activateGroupPermissions();
    }
    if ((event.target.dataset.chatPermissionsTarget === 'canSendMessages' ||
        event.target.dataset.chatPermissionsTarget === 'canSendAudios' ||
        event.target.dataset.chatPermissionsTarget === 'canSendDocuments' ||
        event.target.dataset.chatPermissionsTarget === 'canSendPhotos' ||
        event.target.dataset.chatPermissionsTarget === 'canSendVideos' ||
        event.target.dataset.chatPermissionsTarget === 'canSendVideoNotes' ||
        event.target.dataset.chatPermissionsTarget === 'canSendVoiceNotes') && !event.target.checked) {
      this.deactivateGeneralPermissions();
    }

    // Activa 'canSendMessages' si se activa 'canSendPolls'
    if ((event.target.dataset.chatPermissionsTarget === 'canSendPolls') && event.target.checked) {
      this.canSendMessagesTarget.checked = event.target.checked;
    }

    if ((event.target.dataset.chatPermissionsTarget === 'canSendMessages') && !event.target.checked) {
      this.canSendPollsTarget.checked = false;
    }
  }

  activateGroupPermissions() {
    [
      this.canSendMessagesTarget,
      this.canSendAudiosTarget,
      this.canSendDocumentsTarget,
      this.canSendPhotosTarget,
      this.canSendVideosTarget,
      this.canSendVideoNotesTarget,
      this.canSendVoiceNotesTarget
    ].forEach(checkbox => checkbox.checked = true);
  }

  deactivateGeneralPermissions() {
    this.canSendOtherMessagesTarget.checked = false;
    this.canAddWebPagePreviewsTarget.checked = false;
  }
}
