import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-all"
export default class extends Controller {
  static targets = ["selectAll", "checkBox"]

  connect() {
    this.updateSelectAllStatus();
  }

  toggleAll() {
    const checked = this.selectAllTarget.checked;
    this.checkBoxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  checkSelectAll() {
    const allChecked = this.checkBoxTargets.every((checkbox) => checkbox.checked);
    const anyUnchecked = this.checkBoxTargets.some((checkbox) => !checkbox.checked);

    this.selectAllTarget.checked = allChecked;
    this.selectAllTarget.indeterminate = !allChecked && anyUnchecked;
  }

  updateSelectAllStatus() {
    this.checkSelectAll();
  }
}