import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-range-picker"
export default class extends Controller {
  connect() {
    const element = this.element;
    const ranges = {
      [element.dataset.today]: [moment(), moment()],
      [element.dataset.yesterday]: [moment().subtract({ days: 1 }), moment().subtract({ days: 1 })],
      [element.dataset.lastSevenDays]: [moment().subtract({ days: 6 }), moment()],
      [element.dataset.lastThirtyDays]: [moment().subtract({ days: 29 }), moment()],
      [element.dataset.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
      [element.dataset.lastMonth]: [moment().subtract({ months: 1 }).startOf('month'), moment().subtract({ months: 1 }).endOf('month')],
      [element.dataset.lastThreeSixtyFiveDays]: [moment().subtract({ days: 364 }), moment()],
    };

    new DateRangePicker(element, {
      alwaysShowCalendars: true,
      autoApply: true,
      showWeekNumbers: true,
      opens: 'center',
      ranges: ranges,
      locale: {
        format: "DD/MM/YYYY",
      }
    }, this.submit.bind(this));
  }

  submit(start, end) {
    this.element.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
    this.element.dispatchEvent(new Event('change'));
  }
}
