import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  changeTab(event) {
    const classesToRemove = ['bg-white', 'lg:ring-1', 'lg:ring-inset', 'lg:bg-white/10', 'lg:ring-white/10'];

    this.tabTargets.forEach((tab, index) => {
      classesToRemove.forEach(cl => tab.classList.remove(cl));
      this.panelTargets[index].style.display = 'none';
    })

    const selectedTab = event.currentTarget;
    classesToRemove.forEach(cl => selectedTab.classList.add(cl));
    const panelIndex = this.tabTargets.indexOf(selectedTab);
    this.panelTargets[panelIndex].style.display = 'block';
  }
}
