import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown-and-refresh"
export default class extends Controller {
  static targets = ["countdown"]

  connect() {
    this.countdown();
  }

  countdown() {
    let counter = 5;
    this.countdownTarget.textContent = counter;

    const interval = setInterval(() => {
      counter--;
      this.countdownTarget.textContent = counter;

      if (counter <= 0) {
        clearInterval(interval);
        // Aquí puedes redirigir o recargar la página
        window.location.reload();
      }
    }, 1000);
  }
}