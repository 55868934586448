import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "csvContent", "textContent"]

  open(event) {
    event.preventDefault();
    this.modalTarget.classList.remove('hidden');
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();
    this.modalTarget.classList.add('hidden');
  }

  chooseCSV(event) {
    event.preventDefault()
    this.csvContentTarget.classList.remove('hidden')
    this.textContentTarget.classList.add('hidden')
    this.textContentTarget.querySelector('textarea').value = '';
  }

  chooseText(event) {
    event.preventDefault()
    this.textContentTarget.classList.remove('hidden')
    this.csvContentTarget.classList.add('hidden')
    this.csvContentTarget.querySelector('input[type="file"]').value = null;
  }
}
