import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchInput', 'optionsList', 'dataSelected']

  connect() {
    this.fullOptionsList = Array.from(this.optionsListTarget.querySelectorAll('li'));
    this.showLimitedOptions();
    this.optionsListTarget.style.display = "none";

    // Update the search input value based on the selected option in the select
    const selectedValue = this.dataSelectedTarget.value;
    const selectedOption = this.fullOptionsList.find(option => option.dataset.optionValue === selectedValue);
    if (selectedOption) {
      this.searchInputTarget.value = selectedOption.textContent.trim();
    }

    // Add listener to hide options when clicking outside the element
    document.addEventListener('click', (event) => this.closeOnOutsideClick(event));
  }

  showLimitedOptions() {
    // Limit to 10 items
    const limitedOptions = this.fullOptionsList;

    this.optionsListTarget.innerHTML = '';
    limitedOptions.forEach(option => {
      this.optionsListTarget.appendChild(option.cloneNode(true));
    });
  }

  filterOptions() {
    if (this.optionsListTarget.style.display === "none") {
      this.showOptions();
    }

    const searchTerm = this.searchInputTarget.value.toLowerCase().replace(/\s+/g, '');
    const filteredOptions = this.fullOptionsList.filter(option => {
      const optionText = option.textContent.toLowerCase().replace(/\s+/g, '');
      return optionText.includes(searchTerm);
    });

    // Limit to 10 items
    const limitedOptions = filteredOptions.slice(0, 10);

    this.optionsListTarget.innerHTML = '';
    limitedOptions.forEach(option => {
      this.optionsListTarget.appendChild(option.cloneNode(true));
    });

    // Here you can update the class of the selected option
    this.refreshSelectedClass();
  }

  selectOption(event) {
    const selectedValue = event.currentTarget.dataset.optionValue;

    // Update the search input
    this.searchInputTarget.value = event.currentTarget.textContent.trim();

    // Update the underlying 'select' element
    this.dataSelectedTarget.value = selectedValue;

    // Fire a change event on the 'select' element
    const changeEvent = new Event('change');
    this.dataSelectedTarget.dispatchEvent(changeEvent);

    // Update the UI to reflect the selection
    this.refreshSelectedClass();

    // Hide the options
    this.hideOptions();
  }

  refreshSelectedClass() {
    const selectedValue = this.dataSelectedTarget.value;
    const allOptions = Array.from(this.optionsListTarget.querySelectorAll('li'));

    const svgHTML = `<svg class="h-5 w-5 fill-current text-shadowy-300 group-hover:text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                       <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                     </svg>`;

    allOptions.forEach(option => {
      const optionValue = option.dataset.optionValue;

      // Find or create the SVG container within the option
      let svgContainer = option.querySelector('.svg-container');
      if (!svgContainer) {
        svgContainer = document.createElement('span');
        svgContainer.className = 'svg-container absolute inset-y-0 right-0 flex items-center pr-4';
        option.appendChild(svgContainer);
      }

      if (optionValue === selectedValue) {
        // Add the classes you want for the selected option
        option.classList.add('text-shadowy-300', 'font-semibold');

        // Add the SVG if not present
        if (!svgContainer.innerHTML) {
          svgContainer.innerHTML = svgHTML;
        }
      } else {
        // Remove the classes you want for the unselected option
        option.classList.remove('text-shadowy-300', 'font-semibold');

        // Remove the SVG if present
        svgContainer.innerHTML = '';
      }
    });
  }

  updateSearchInput() {
    // Update the search input value based on the selected option in the select
    const selectedValue = this.dataSelectedTarget.value;

    const selectedOption = this.fullOptionsList.find(option => option.dataset.optionValue === selectedValue);
    if (selectedOption) {
      this.searchInputTarget.value = selectedOption.textContent.trim();
    }
  }

  showOptions() {
    this.showLimitedOptions();

    // Ensure that classes get updated before showing the options
    this.refreshSelectedClass();
    this.optionsListTarget.style.display = "block";

    // Select all the text in the input
    this.searchInputTarget.select();

  }

  clearInput() {
    this.searchInputTarget.value = '';
  }

  hideOptions() {
    // Comprobar si el valor seleccionado existe en la lista completa
    const selectedValue = this.dataSelectedTarget.value;
    const selectedOption = this.fullOptionsList.find(option => option.dataset.optionValue === selectedValue);

    // Si el valor seleccionado no está en la lista, regenerar la lista
    if (!selectedOption) {
      const selectedText = this.dataSelectedTarget.options[this.dataSelectedTarget.selectedIndex].text;
      this.addOptionToList(selectedText, selectedValue);
    }

    this.optionsListTarget.style.display = "none";

    // Actualiza el campo de entrada
    this.updateSearchInput();
  }

  addOptionToList(text, value) {
    // Crear el elemento 'li' y asignarle clases y atributos
    const li = document.createElement('li');
    li.className = 'group relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-blue-500 hover:text-white cursor-pointer text-shadowy-300 text-shadowy-300 font-semibold';
    li.dataset.action = "click->combobox-with-search-component#selectOption";
    li.dataset.optionValue = value;
    li.setAttribute('role', 'option');
    li.setAttribute('tabindex', '-1');

    // Crear el span para el texto del producto
    const spanText = document.createElement('span');
    spanText.className = 'block truncate';
    spanText.textContent = text;

    // Crear el contenedor para el SVG
    const svgContainer = document.createElement('span');
    svgContainer.className = 'svg-container absolute inset-y-0 right-0 flex items-center pr-4';

    // SVG content
    const svgHTML = `<svg class="h-5 w-5 fill-current text-shadowy-300 group-hover:text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                       <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                     </svg>`;

    // Añadir el contenido SVG al contenedor
    svgContainer.innerHTML = svgHTML;

    // Añadir los elementos hijos al 'li'
    li.appendChild(spanText);
    li.appendChild(svgContainer);

    // Añadir el nuevo 'li' a la lista completa
    this.fullOptionsList.push(li);

    // Actualizar la lista visible
    this.showLimitedOptions();

    // Asegurarse de que la clase del elemento seleccionado se actualice
    this.refreshSelectedClass();
  }


  regenerateFullOptionsList() {
    // Actualizar fullOptionsList con las opciones del select
    this.fullOptionsList = Array.from(this.optionsListTarget.querySelectorAll('li'));

    // Actualizar la lista visible
    this.showLimitedOptions();

    // Asegurarse de que la clase del elemento seleccionado se actualice
    this.refreshSelectedClass();
  }



  toggleOptions() {
    if (this.optionsListTarget.style.display === "none") {
      this.showOptions();
    } else {
      this.hideOptions();
    }
  }

  closeOnOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.hideOptions();
    }
  }
}
