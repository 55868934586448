import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showedTopic", "hiddenTopic", "hideButton", "showButton"]

  showTopics() {
    this.hiddenTopicTargets.forEach(element => {
      element.classList.remove('hidden');
    });
    this.showButtonTarget.classList.add('hidden');
    this.hideButtonTarget.classList.remove('hidden');
  }

  hideTopics() {
    this.hiddenTopicTargets.forEach(element => {
      element.classList.add('hidden');
    });
    this.hideButtonTarget.classList.add('hidden');
    this.showButtonTarget.classList.remove('hidden');
  }
}
