import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="dashboards"
export default class extends Controller {
  static targets = ['group', 'textPlain', 'textHtml', 'textPlainSelector', 'textHtmlSelector']

  groupChanged(event) {
    const groupId = this.groupTarget.value;
    const url = `/manager/topics/get_topics?id=${groupId}&origin=dashboards`;
    Turbo.visit(url);
  }

  changeTextMode(event) {
    const selectedMode = event.target.value;
    if (selectedMode === 'HTML') {
      this.textPlainTarget.classList.add('hidden');
      this.textPlainSelectorTarget.disabled = true;
      this.textHtmlTarget.classList.remove('hidden');
      this.textHtmlSelectorTarget.disabled = false;
    } else {
      this.textHtmlTarget.classList.add('hidden');
      this.textHtmlSelectorTarget.disabled = true;
      this.textPlainTarget.classList.remove('hidden');
      this.textPlainSelectorTarget.disabled = false;
    }
  }

}
