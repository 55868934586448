import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "editButton", "saveButton", "cancelButton"]
  static values = { action: String, key: String }

  connect() {
    this.toggleButtons(false);
    this.adjustInputWidth();
  }

  adjustInputWidth() {
    const input = this.inputTarget;
    if (input) {
      // Crear un elemento span para medir el ancho del texto
      const span = document.createElement('span');
      document.body.appendChild(span);

      // Aplicar el mismo estilo al span que al input
      span.style.font = window.getComputedStyle(input).font;
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'pre';
      span.textContent = input.value || input.placeholder;

      // Ajustar el ancho del input al ancho del texto
      input.style.width = `${span.offsetWidth + 10}px`; // +10 para un poco de espacio adicional

      // Limpiar al eliminar el span
      document.body.removeChild(span);
    }
  }

  toggleEdit() {
    this.inputTarget.disabled = false;
    setTimeout(() => {
      this.inputTarget.focus();
      const value = this.inputTarget.value;
      this.inputTarget.value = '';
      this.inputTarget.value = value;
    }, 0);
    this.toggleButtons(true);
    this.adjustInputWidth();
  }

  save() {
    let data = new FormData();
    data.append(this.keyValue, this.inputTarget.value);

    Rails.ajax({
      type: "PATCH",
      url: this.actionValue,
      data: data,
      success: () => {
        this.toggleButtons(false);
        this.inputTarget.disabled = true;
      },
      error: (err) => {
        Swal.fire({
          title: 'Error',
          text: err.message,
          icon: 'error',
        });
        this.toggleButtons(false);
      },
    });
    this.adjustInputWidth();
  }

  cancel() {
    this.toggleButtons(false);
    this.inputTarget.disabled = true;
    this.inputTarget.value = this.inputTarget.dataset.editableInputInitialValue; // Restablecer el valor inicial
  }

  toggleButtons(showSaveAndCancel) {
    this.editButtonTarget.classList.toggle('hidden', showSaveAndCancel);
    this.saveButtonTarget.classList.toggle('hidden', !showSaveAndCancel);
    this.cancelButtonTarget.classList.toggle('hidden', !showSaveAndCancel);
  }
}