// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "fontawesome"
import './add_sweetalert2'
import './turbo_set_confirm_method'
import './add_moment'
import './add_vanilla_datetimerange_picker'
import 'chartkick/chart.js'
import "trix"
