import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="cookie-banner"
export default class extends Controller {
  acceptCookies() {
    this.updateConsent(true);
  }

  rejectCookies() {
    this.updateConsent(false);
  }

  updateConsent(consent) {
    Rails.ajax({
      type: 'POST',
      url: '/set_cookie_consent',
      data: `consent=${consent}`,
      success: () => {
        this.element.style.display = 'none';
        this.loadGoogleAnalytics();
      },
      error: (err) => {
        console.error("Error al actualizar el consentimiento de cookies:", err);
      },
    });
  }

  loadGoogleAnalytics() {
    // Create the first script tag for the Google Analytics URL
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-FB74MQFLZE";
    document.head.appendChild(gtagScript);

    // Create the second script tag for the configuration
    const script = document.createElement('script');
    const inlineScript = document.createTextNode(`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-FB74MQFLZE', { 'anonymize_ip': true, 'allow_ad_personalization_signals': false });
  `);
    script.appendChild(inlineScript);
    document.head.appendChild(script);
  }
}