import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'avatar', 'container'];

  connect() {
    this.inputTarget.addEventListener('change', this.handleImageChange.bind(this));
  }

  changeImage() {
    this.inputTarget.click();
  }

  handleImageChange() {
    const file = this.inputTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.updateAvatar(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  updateAvatar(imageData) {
    const container = this.containerTarget;

    const img = document.createElement('img');
    img.src = imageData;
    img.className = "h-12 w-12 rounded-full object-cover object-center"; // <-- Añadimos las clases aquí
    img.setAttribute('data-form-field-component-target', 'avatar'); // Añadir el data target al nuevo elemento de imagen

    container.replaceChild(img, this.avatarTarget);
  }
}
