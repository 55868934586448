import { Controller } from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

// Connects to data-controller="telegram-users"
export default class extends Controller {
  static targets = ['group']

  groupChanged(event) {
    const groupId = this.groupTarget.value;
    const url = `/manager/telegram_users/get_topics?id=${groupId}`;
    Turbo.visit(url);
  }
}
